<template>
  <div>
    <!-- Studentlar uchun -->
    <b-row>
      <b-col md="8">
        <statistics
          v-if="false"
          :data="statistic"
        />
        <user-profile-card :profile="userProfileData" />
        <ClassesTableCard
          v-if="('manage', 'student')"
          :profile="userProfileData"
          class="mb-2"
        />

        <!-- <courses-table-card
          class="mb-2"
        /> -->

      </b-col>
      <b-col md="4">
        <template>
          <div
            v-for="(card, index) in GET_STUDENT_COURSE"
            :key="index"
          >
            <course-progress-card :data="card" />
          </div>
        </template>
        <!-- <memory-limit
          :data="{ all: '10 GB', completed: '9 GB', inProgress: '1 GB', series: [ 90 ] }"
        /> -->
        <transaction-card />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import { mapGetters, mapActions } from 'vuex'
import transactionCard from './components/transactionsCard.vue'
// import coursesTableCard from './components/coursesTableCard.vue'
import userProfileCard from './components/userProfileCard.vue'
import courseProgressCard from './components/courseProgressCard.vue'
import ClassesTableCard from './components/CourseTableCard.vue'
import useJwt from '@/auth/jwt/useJwt'
// import MemoryLimit from './components/MemoryLimit.vue'
import Statistics from './components/Statistics.vue'
// import HeadCards from '@/components/HeadCards.vue'

export default {
  components: {
    BRow,
    BCol,
    transactionCard,
    // coursesTableCard,
    userProfileCard,
    courseProgressCard,
    ClassesTableCard,
    // MemoryLimit,
    // HeadCards,
    Statistics,
  },
  data() {
    return {
      courseProgress: [
        {
          name: 'Новый курс 1',
          subject: 'Математика',
          series: [80, 20],
        },
        {
          name: 'Новый курс 2',
          subject: 'Английский',
          series: [10, 90],
        },
      ],
      statistic: [
        {
          icon: 'UsersIcon', color: 'light-primary', title: '100', subtitle: 'Всего ползователей', customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'UserIcon', color: 'light-info', title: '20', subtitle: 'Сотрудников', customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'UserIcon', color: 'light-danger', title: '10', subtitle: 'Учителей', customClass: 'mb-2 mb-sm-0',
        },
        {
          icon: 'UserIcon', color: 'light-success', title: '70', subtitle: 'Учеников', customClass: '',
        },
      ],
    }
  },
  computed: {
    ...mapGetters('contacts', ['GET_STUDENT_COURSE']),
    userProfileData() {
      return useJwt.getUserData()
    },
  },
  mounted() {
    this.FETCH_STUDENT_COURSE(this.userProfileData.contact.id)
  },
  methods: {
    ...mapActions('contacts', ['FETCH_STUDENT_COURSE']),
  },
}
</script>
