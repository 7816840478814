var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"card-company-table",attrs:{"no-body":""}},[_c('b-card-header',[_c('b-card-title',[_vm._v("Последняя активность")])],1),_c('b-table',{staticClass:"mb-0",attrs:{"items":_vm.GET_STUDENT_COURSE,"responsive":"","fields":_vm.fields,"show-empty":"","empty-text":_vm.$t('general.no_records_to_show')},on:{"row-clicked":function (e) { return _vm.$router.push(("/course/" + (e.course.id) + "/" + (e.id))); }},scopedSlots:_vm._u([{key:"cell(index)",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s(index + 1)+" ")]}},{key:"head()",fn:function(ref){
var label = ref.label;
return [_vm._v(" "+_vm._s(_vm.$t(label))+" ")]}},{key:"cell(title)",fn:function(data){return [_c('div',{staticClass:"font-weight-bolder"},[_vm._v(" "+_vm._s(data.item.course.title)+" ")]),(data.item.course.subject[_vm.$i18n.locale])?_c('small',{staticClass:"text-muted"},[_vm._v(" "+_vm._s(data.item.course.subject[_vm.$i18n.locale])+" ")]):_vm._e()]}},{key:"cell(access)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$_dateTimeFormatter(item.access_start_date).split(',')[0])+" - "+_vm._s(_vm.$_dateTimeFormatter(item.access_end_date).split(',')[0])+" ")]}},{key:"cell(group)",fn:function(ref){
var value = ref.value;
return [_c('b-badge',{attrs:{"variant":value ? 'success': 'danger'}},[_vm._v(" "+_vm._s(value ? value: 'Индивидуал')+" ")])]}},{key:"cell(teachers)",fn:function(ref){
var item = ref.item;
return [_c('b-avatar-group',{attrs:{"size":"32px"}},_vm._l((item.teachers),function(teacher){return _c('b-avatar',{key:teacher.id,staticClass:"pull-up",attrs:{"variant":"info","title":teacher.full_name,"src":teacher.photo}})}),1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }