<template>
  <div>
    <home-page />
  </div>
</template>

<script>
import homePage from './modules/Home/homePage.vue'

export default {
  components: {
    homePage,
  },
}
</script>

<style>

</style>
