<template>
  <b-card>
    <b-row>
      <!-- User Info: Left col -->
      <b-col
        cols="21"
        xl="6"
        class="d-flex justify-content-between flex-column"
      >
        <!-- User Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">
          <b-avatar
            :src="profile.contact.photo"
            variant="light-primary"
            size="140px"
            rounded
          />
          <div class="d-flex flex-column ml-1">
            <div>
              <div>
                <h3 class="mb-0">
                  {{ profile.contact.last_name }}
                </h3>
              </div>
              <div>
                <h3 class="mb-0">
                  {{ profile.contact.first_name }}
                </h3>
              </div>
              <div class="mb-1">
                <h3>
                  {{ profile.contact.middle_name }}
                </h3>
              </div>
              <div class="mb-25">
                <span class="card-text">#ID {{ profile.contact.id }}</span>
              </div>
              <div>
                <span>
                  <feather-icon
                    icon="PhoneIcon"
                  /> +{{ profile.contact.phone }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </b-col>

      <!-- Right Col: Table -->
      <b-col
        cols="12"
        xl="6"
      >
        <b-list-group>
          <b-list-group-item class="d-flex justify-content-between">
            <div>
              <span class="mr-1">
                <feather-icon
                  icon="AwardIcon"
                  size="16"
                />
              </span>
              <span class="text-uppercase">{{ $t('users.role') }}</span>
            </div>
            <div
              v-if="profile.roles.length"
              class="d-flex justify-content-end align-items-center"
            >
              <b-badge
                v-for="role in profile.roles"
                :key="role.id"
                variant="primary"
                class="ml-50 "
              >
                {{ role.title[$i18n.locale] }}
              </b-badge>
            </div>
          </b-list-group-item>
          <b-list-group-item class="d-flex justify-content-between">
            <div>
              <span class="mr-1">
                <feather-icon
                  icon="BarChartIcon"
                  size="16"
                />
              </span>
              <span>{{ $t('users.level') }}</span>
            </div>
            <div><h4 class="mb-0">
              {{ profile.contact.level }}
              <span
                class="text-muted mb-0"
                style="font-size: 15px"
              >LVL</span>
            </h4></div>
          </b-list-group-item>
          <b-list-group-item class="d-flex justify-content-between">
            <div>
              <span class="mr-1">
                <feather-icon
                  icon="CalendarIcon"
                  size="16"
                />
              </span>
              <span>{{ $t('users.date_joined') }}</span>
            </div>
            <div>{{ new Date(profile.contact.created_date).toLocaleString().split(',')[0] }}</div>
          </b-list-group-item>
        </b-list-group>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BAvatar, BRow, BCol, BListGroup, BListGroupItem, BBadge,
} from 'bootstrap-vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BAvatar,
    BListGroup,
    BListGroupItem,
    BBadge,
    FeatherIcon,
  },
  props: {
    profile: {
      type: Object,
      required: true,
    },
  },
}
</script>
